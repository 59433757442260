import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ua from 'locales/ua/translations.json';
import en from 'locales/en/translations.json';
import ge from 'locales/ge/translations.json';

import App from './App';

import 'styles/main.scss';
import 'swiper/css';

const resources = {
  ua: {
    translation: ua,
  },
  en: {
    translation: en,
  },
  ge: {
    translation: ge,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ua',
  fallbackLng: 'ua',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<>Loading</>}>
      <App />
    </Suspense>
  </React.StrictMode>
);
