import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import loadable from '@loadable/component';

const DefaultPage = loadable(() => import('pages/DefaultPage'), {
  resolveComponent: (component) => component.DefaultPage,
});

const ExamplePage = loadable(() => import('pages/ExamplePage'), {
  resolveComponent: (component) => component.ExamplePage,
});

const NotFoundPage = loadable(() => import('pages/NotFoundPage'), {
  resolveComponent: (component) => component.NotFoundPage,
});

export const Router = () => {
  return (
    <Routes>
      <Route element={<DefaultPage />} path="/:companyId" index />
      <Route element={<ExamplePage />} path="/verify/:id" />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};
